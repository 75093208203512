(function(){
    document.querySelectorAll('[data-toggle]')
        .forEach((element) => {

            let toggleElement     = document.querySelector(element.dataset.toggle);
            let toggleClass       = (toggleElement.dataset.toggleClass ?? 'active').split(' ');
            let toggleButtonClass = (element.dataset.toggleClass ?? 'active').split(' ');

            let isStrict = element.dataset.toggleStrict !== undefined;

            element.addEventListener('click', (event) => {
                //if we're using strict toggle and the target isn't the clicked element we do nothing
                if(isStrict && event.target != element){
                    return;
                }

                event.preventDefault();

                //when checking active we will only check the first class provided
                if(toggleElement.classList.contains(toggleClass[0])){
                    element.classList.remove(...toggleButtonClass);
                    toggleElement.classList.remove(...toggleClass);
                } else {
                    element.classList.add(...toggleButtonClass);
                    toggleElement.classList.add(...toggleClass);
                }

            })

        })
})();
