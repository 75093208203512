(function(){
    document.querySelectorAll('[data-no-scroll]')
        .forEach((element) => {
            let scrollClass = element.dataset.noScroll != ''
                ? element.dataset.noScroll
                : 'overflow-y-hidden';
            
            element.addEventListener('change', (event) => {
                if(event.target.checked){
                    document.body.classList.add(...scrollClass.split(' '));
                } else {
                    document.body.classList.remove(...scrollClass.split(' '));
                }
            })

        })
})();
