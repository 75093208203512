import Swiper, { Navigation, Pagination, Grid } from 'swiper';

window.customConfigs = {
    'single-slide' : (carousel) => ({
        slidesPerView : 1,
        autoHeight    : true,
        navigation    : {
            prevEl        : `.${carousel.dataset.paginationPrev}`,
            nextEl        : `.${carousel.dataset.paginationNext}`,
            disabledClass : 'swiper-button-disabled cursor-default'
        },
    }),

    'auto-slides-spaced' : (carousel) => ({
        slidesPerView : 'auto',
        spaceBetween  : 30,
        navigation     : {
            prevEl        : `.${carousel.dataset.paginationPrev}`,
            nextEl        : `.${carousel.dataset.paginationNext}`,
            disabledClass : 'swiper-button-disabled cursor-default'
        },
    }),

    'auto-slides-centered' : {
        slidesPerView  : 'auto',
        centerInsufficientSlides : true,
        pagination     : {
            el                : ".swiper-pagination",
            clickable         : true,
            bulletClass       : "swiper-pagination-bullet only:hidden w-3 h-3 transition-colors block bg-light rounded-full",
            bulletActiveClass : "swiper-pagination-bullet-active !bg-secondary"
        }
    },

    'auto-slides' : {
        slidesPerView : 'auto',
        pagination    : {
            el                : ".swiper-pagination",
            clickable         : true,
            bulletClass       : "swiper-pagination-bullet only:hidden w-3 h-3 transition-colors block bg-light rounded-full",
            bulletActiveClass : "swiper-pagination-bullet-active !bg-secondary"
        }
    },
}

const defaultConfig = {
    modules: [
        Navigation,
        Pagination,
        Grid
    ],

    pagination: {
        el: ".swiper-pagination"
    }
};

(function() {
    let currentCarouselIndex = 0;
    window.siteCarousels = new Map();
    document.querySelectorAll('.swiper')
        .forEach(carousel => {
            let config             = (customConfigs[carousel.dataset.config] ?? {});
            let carouselIdentifier = `swiper-${currentCarouselIndex}`;

            carousel.classList.add(carouselIdentifier);

            if(config instanceof Function){
                config = config(carousel);
            }

            defaultConfig.pagination.el = `.${carouselIdentifier} ${defaultConfig.pagination.el}`;

            siteCarousels.set(
                carousel,
                new Swiper(carousel, {
                    ...defaultConfig,
                    ...config
                })
            );
        });
})();

export default Swiper;