(function(){

    let headerElement = document.querySelector('header.site-header');

    //offsets when scrolling before interaction occurs
    //e.g. showOffset 50 means you must scroll 50px upwards for the menu to show
    let showOffset = 50;
    let hideOffset = 20;

    //initialise the offsets with their totals
    let currentOffsetBeforeShow = showOffset;
    let currentOffsetBeforeHide = hideOffset;

    //the scroll offset starts from our position
    let previousScroll = window.scrollY;

    //no point in adding the event listeners if the header doesn't exist
    if(headerElement){
        handleHeaderScroll();
        headerElement.classList.remove('site-header--hidden'); //if we load half way down the page we want the menu to be shown

        window.addEventListener('scroll', handleHeaderScroll);
    }

    /**
     * Handles the movements of the header on scroll
     */
    function handleHeaderScroll(){
        //if the previousScroll is > current we're moving upwards
        if(previousScroll > window.scrollY){ //upwards

            currentOffsetBeforeShow -= previousScroll - window.scrollY; //handle the show offset movement

            if(currentOffsetBeforeShow <= 0){ //if we've scrolled upwards more than the offset
                headerElement.classList.remove('site-header--hidden'); //show the header again
            }

            currentOffsetBeforeHide = hideOffset; //we've scrolled up so we should reset the hide offset

        } else { //downwards

            currentOffsetBeforeHide -= window.scrollY - previousScroll; //handle the hide offset movement

            if(currentOffsetBeforeHide <= 0){ //if we've reached the hide offset
                headerElement.classList.add('site-header--hidden'); //hide the header
            }

            currentOffsetBeforeShow = showOffset; //reset the show offset

        }

        previousScroll = window.scrollY; //keep track of the previous scroll so we know how far we've moved since the last event

        if(previousScroll <= 0){ //if we're at the top add a class so we can stop hide and show from having any effect
            headerElement.classList.add('site-header--top');
            headerElement.classList.remove('site-header--hidden'); //sometimes we hit the top without removing hidden
        } else {
            headerElement.classList.remove('site-header--top');
        }
    }

})();